import * as React from 'react';

import { useLocaleString } from '@devsisters/gatsby-plugin-i18n';

interface PageProps {
  location: Location;
}
const Page: React.FC<PageProps> = () => {
  const locale = useLocaleString();
  React.useEffect(() => {
    location.href = `https://policy.devsisters.com/${locale}/terms-of-service`;
  }, []);
  return null;
};

export default Page;
